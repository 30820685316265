import "/src/assets/main.css";

import { init } from "../src/init";

window.addEventListener("message", (event) => {
	if (event.data.type === "scrinz:init") {
		const { router } = init();
		router.replace("/");
	}
});
